import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import ContactContainer from "../containers/contact";
import Layout from "../layouts";
import Header from "../layouts/header";

const ContactPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="AL Carretas & Trailers" />
                <a
                    onClick={() => {
                        return gtag_report_conversion(
                            "https://api.whatsapp.com/send?phone=5562998496335&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
                        );
                    }}
                    href="https://api.whatsapp.com/send?phone=5562998496335&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
                    className="float"
                >
                    <i className="icofont-whatsapp my-float"></i>
                </a>
                <div className="main-wrapper">
                    <Header />
                    <ContactContainer />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ContactPage;
