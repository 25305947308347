import React from "react";
import PropTypes from "prop-types";
import PageTitle from "../../../components/page-title";

const PageTitleContainer = ({ title, subTitle, price, totalPrice, divide }) => {
    return (
        <div className="section">
            <div className="container">
                <PageTitle
                    title={title}
                    subTitle={subTitle}
                    price={price}
                    totalPrice={totalPrice}
                    divide={divide}
                />
            </div>
        </div>
    );
};

PageTitleContainer.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.array,
    price: PropTypes.string,
    totalPrice: PropTypes.string,
    divide: PropTypes.string,
};

export default PageTitleContainer;
