import PropTypes from "prop-types";

const Testimonial = ({ data }) => {
    return (
        <div className="single-testimonial">
            <i className={data.icon}></i>

            <div className="testimonial-content">
                <img
                    className="fit-image"
                    src={process.env.PUBLIC_URL + data.backgroundImage}
                    alt="depoimento"
                />
            </div>
        </div>
    );
};

Testimonial.propTypes = {
    data: PropTypes.object,
};

export default Testimonial;
