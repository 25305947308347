import React, { useEffect, useRef } from "react";

function Map() {
    return (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3820.8512649063!2d-49.36256244898456!3d-16.73427148841652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935ef73ed079206d%3A0x560d1b0c117d97fb!2sA%26L%20Carretas%20e%20Trailleres!5e0!3m2!1spt-BR!2sbr!4v1639488989240!5m2!1spt-BR!2sbr"
            className="mapLocation"
            allowFullScreen=""
            loading="lazy"
        ></iframe>
    );
}

export default Map;
