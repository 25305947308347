import PropTypes from "prop-types";
import React from "react";
import PageTitleContainer from "../containers/global/page-title";
import ProjectDetailsContainer from "../containers/project-details";
import Layout from "../layouts";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import ProjectData from "../data/project.json";
import SEO from "../components/seo";

const ProjectDetails = ({
    match: {
        params: { id },
    },
}) => {
    const data = ProjectData.filter((project) => project.id === id);

    const title = data[0].title;
    const price = data[0].price;
    const totalPrice = data[0].totalPrice;
    const divide = data[0]?.divide;
    const cate = data[0].categories.map((value, idx) => {
        return (
            <span className="d-inline" key={idx}>
                {value}
                {idx !== data[0].categories.length - 1 && " , "}
            </span>
        );
    });
    return (
        <React.Fragment>
            <Layout>
                <SEO title="AL Carretas & Traillers" />
                <a
                    onClick={() => {
                        return gtag_report_conversion(
                            "https://api.whatsapp.com/send?phone=5562998496335&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
                        );
                    }}
                    href="https://api.whatsapp.com/send?phone=5562998496335&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
                    className="float"
                >
                    <i className="icofont-whatsapp my-float"></i>
                </a>
                <div className="main-wrapper">
                    <Header />
                    <PageTitleContainer
                        title={title}
                        subTitle={cate}
                        price={price}
                        totalPrice={totalPrice}
                        divide={divide}
                    />
                    <ProjectDetailsContainer data={data[0]} />
                    <Footer />
                </div>
            </Layout>
        </React.Fragment>
    );
};

ProjectDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
};

export default ProjectDetails;
