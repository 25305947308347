const HomeAbout = () => {
    return (
        <div className="section bg-dark overflow-hidden">
            <div className="about-section section-padding mt-n3 mb-n1">
                <div
                    className="about-images"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/al-about-images.webp)`,
                    }}
                ></div>

                <div className="container">
                    <div
                        style={{ marginLeft: "20px" }}
                        className="row justify-content-end"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        <div className="col-lg-6">
                            <div className="about-content">
                                <h3 className="title">Sobre nós</h3>

                                <p className="about-text">
                                    AL Carretas surge com a missão de
                                    proporcionar a segurança necessária para a
                                    melhor experiência no transporte de cargas.
                                </p>
                                <p>
                                    Especialistas com uma ampla experiência no
                                    ramo de carretas. Equipamentos profissionais
                                    e um rigoroso processo de fabricação que
                                    propiciam a qualidade e a durabilidade nos
                                    produtos.
                                </p>
                                <p>
                                    Uma linha completa de carretas para
                                    transporte de objetos, equipamentos, motos,
                                    animais, cargas, barcos, entre outros.
                                </p>
                                <p>
                                    Seguindo todas especificações do Detran e
                                    Denatran para comercializar produtos e de
                                    acordo com a legislação vigente, garantindo
                                    a segurança dos clientes.
                                </p>

                                <p>
                                    Modelos prontos e sob medida.
                                    <br /> <br />
                                    Faça já o seu pedido..
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAbout;
