import PropTypes from "prop-types";
import Pricing from "../pricing";

const PageTitle = ({
    title,
    subTitle,
    price = "non",
    totalPrice = "non",
    divide = "18",
}) => {
    return (
        <div className="page-banner-title" data-aos="fade-up">
            <div className="d-flex justify-content-between align-items-start ">
                <div>
                    <h2 className="title">{title}</h2>
                    <div className="d-flex justify-content-start">
                        <p>{subTitle}</p>
                    </div>
                </div>
                {price === "non" ? (
                    <> </>
                ) : (
                    <Pricing
                        price={price}
                        totalPrice={totalPrice}
                        divide={divide}
                    />
                )}
            </div>
        </div>
    );
};

PageTitle.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.array,
    price: PropTypes.string,
    totalPrice: PropTypes.string,
    divide: PropTypes.string,
};

export default PageTitle;
