import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Pricing = ({ price, totalPrice, divide }) => {
    return (
        <div className="single-pricing-box">
            <div className="pricing-iner">
                <div
                    className="price"
                    dangerouslySetInnerHTML={{
                        __html:
                            `<strong class='price-box'> ${divide}x </strong>` +
                            "R$ " +
                            price,
                    }}
                ></div>
                <br />
                <p className="price-box"> nos cartões de crédito </p>
                <p className="price-box">ou R$ {totalPrice} a vista</p>
            </div>
        </div>
    );
};

Pricing.propTypes = {
    price: PropTypes.string,
    totalPrice: PropTypes.string,
    divide: PropTypes.string,
};

export default Pricing;
