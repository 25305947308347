import PropTypes from "prop-types";
import SocialIcon from "../../components/social-icon";
import { Link } from "react-router-dom";
import ProjectForm from "../../components/project-form";

import { TabContent, TabPane } from "reactstrap";
import SectionTitle from "../../components/section-title";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import Work from "../../components/work";
import WorkData from "../../data/work.json";
import { useState } from "react";

const ProjectDetailsContainer = ({ data }) => {
    const [activeTab, setActiveTab] = useState("1");

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const swiperOption = {
        loop: false,
        speed: 1500,
        spaceBetween: 1,
        slidesPerView: 4,
        autoplay: {
            delay: 500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: ".tab-carousel .swiper-button-next",
            prevEl: ".tab-carousel .swiper-button-prev",
        },
        breakpoints: {
            // when window width is >= 320px
            0: {
                slidesPerView: 1,
            },
            // when window width is >= 480px
            576: {
                slidesPerView: 2,
            },
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
            },
            // when window width is >= 992px
            992: {
                slidesPerView: 3,
            },
            // when window width is >= 1200px
            1200: {
                slidesPerView: 4,
            },
        },
    };

    return (
        <div className="section project-details-section">
            <div className="container">
                <div
                    className="details-middle-content section-padding-02 mt-n2"
                    data-aos="fade-up"
                >
                    <h2 className="title">{data.subTitle}</h2>
                    {data.body.map((value, i) => {
                        return (
                            <div
                                key={i}
                                className="desc"
                                dangerouslySetInnerHTML={{
                                    __html: value,
                                }}
                            />
                        );
                    })}
                </div>

                <div className="details-info section-padding mt-n10">
                    <div
                        className="row"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        <div className="col-lg-12">
                            <div className="single-info d-flex justify-content-start">
                                <h2>Esse modelo conta com:</h2>
                            </div>
                        </div>

                        <div className="col-lg-3">
                            <div className="single-info">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: data.challenge,
                                    }}
                                ></p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="single-info">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: data.date,
                                    }}
                                ></p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="single-info">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: data.team,
                                    }}
                                ></p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="single-info">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: data.service,
                                    }}
                                ></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="work-tab-content">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Swiper className="tab-carousel" {...swiperOption}>
                            {data &&
                                Object.values(data.gallery).map((single, i) => {
                                    return (
                                        <SwiperSlide key={i}>
                                            <div className="single-project-slide">
                                                <div className="thumb">
                                                    <Link
                                                        to={"#"}
                                                        className="image"
                                                    >
                                                        <img
                                                            className="fit-image"
                                                            src={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                single
                                                            }
                                                            alt="Product"
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            <div className="tab-carousel-prev swiper-button-prev">
                                <i className="icofont-thin-left"></i>
                            </div>
                            <div className="tab-carousel-next swiper-button-next">
                                <i className="icofont-thin-right"></i>
                            </div>
                        </Swiper>
                    </TabPane>
                </TabContent>
            </div>

            <div
                className="container"
                data-aos="fade-up"
                data-aos-duration="1200"
            >
                <div className="single-info section-padding-02  mt-n3 d-flex justify-content-center">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: data.quote,
                        }}
                    ></p>
                </div>
            </div>
        </div>
    );
};

ProjectDetailsContainer.propTypes = {
    data: PropTypes.object,
};

export default ProjectDetailsContainer;
