const Footer = () => {
    return (
        <div className="section bg-dark">
            <div className="container">
                <div className="footer-info-box text-center">
                    <h4 className="title">
                        Quer mais informações, fotos ou fazer o seu pedido?
                    </h4>
                    <br />
                    <h4 className="title">Entre em contato conosco pelo</h4>
                    <h2 className="mail">
                        <a
                            onClick={() => {
                                return gtag_report_conversion(
                                    "https://api.whatsapp.com/send?phone=5562998496335&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
                                );
                            }}
                            href="https://api.whatsapp.com/send?phone=5562998496335&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
                        >
                            WhatsApp
                        </a>
                    </h2>
                </div>

                <div className="footer-copyright-social">
                    <div className="footer-copyright">
                        <p>
                            &copy; 2021 <span> AL Carretas & Trailers </span>
                        </p>
                    </div>
                    <div className="footer-social">
                        <p> Siga-nos nas redes sociais</p>
                        <ul className="social">
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="twitter"
                                    href="https://www.instagram.com/alcarretas/"
                                >
                                    Instagram
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="behance"
                                    href="https://www.facebook.com/people/AL-Carretas/100074896714170/"
                                >
                                    Facebook
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
