import ContactForm from "../../components/contact-form";
import Map from "../../components/map/map";

const ContactContainer = () => {
    return (
        <div className="section contact-section bg-dark">
            <div className="container d-flex justify-content-center">
                <div className="contact-section-wrapper">
                    <div className="contact-info" data-aos="fade-up">
                        <span className="number">
                            <a href="tel:+5562998496335">(+55) 62 99849-6335</a>
                        </span>
                        <span className="mail">
                            <a href="contato@alcarretas.com">
                                contato@alcarretas.com
                            </a>
                        </span>
                    </div>
                    <br />
                    <Map />

                    <div
                        className="contact-social"
                        data-aos="fade-up"
                        data-aos-duration="900"
                    >
                        <ul>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="dribbble"
                                    href="https://www.instagram.com/alcarretas/"
                                >
                                    Instagram
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="behance"
                                    href="https://www.facebook.com/people/AL-Carretas/100074896714170/"
                                >
                                    Facebook
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactContainer;
